<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        ref="datepicker"
        hint="DD/MM/YYYY format"
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :max="maxDate"
      no-title
      @input="menu1 = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: (vm) => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,
    rulesDate: [(v) => !!v.length || "Tanggal tidak boleh kosong"],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    maxDate() {
      let date = new Date();
      var endDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      this.$emit("date-changed", this.dateFormatted);
    },
  },
  props: {
    label: {
      default: "Date",
      type: String,
    },
    reference: {
      default: "Date",
      type: String,
    },
  },
};
</script>
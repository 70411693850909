<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        fullscreen
        v-model="isShowModal"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">Tambah Data</v-btn>
        </template>
        <template v-slot:default="isShowModal">
          <v-toolbar color="teal" dark>
            <v-btn icon dark @click="closeModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            Data Pendaftar
          </v-toolbar>
          <v-layout justify-center>
            <v-card>
              <alert ref="alert" />
              <v-card-text>
                <v-card>
                  <v-card-text>
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="form.kk"
                              ref="kk"
                              :counter="16"
                              :rules="rules.nikRules"
                              label="No Kartu Keluarga..."
                              @keypress="filterNumber"
                              hide-details
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="form.nik"
                              ref="nik"
                              :counter="16"
                              :rules="rules.nikRules"
                              label="Nomor Induk Kependudukan..."
                              @keypress="filterNumber"
                              :error-messages="
                                hasErrors && errors.hasOwnProperty('nik')
                                  ? errors.nik[0]
                                  : null
                              "
                              required
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="form.name"
                              ref="name"
                              :rules="rules.nameRules"
                              :error-messages="
                                hasErrors && errors.hasOwnProperty('name')
                                  ? errors.name[0]
                                  : null
                              "
                              label="Nama Sesuai KTP.."
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="form.birth_place"
                              ref="birth_place"
                              :rules="rules.nameRules"
                              :error-messages="
                                hasErrors &&
                                errors.hasOwnProperty('birth_place')
                                  ? errors.birth_place[0]
                                  : null
                              "
                              label="Tempat Kelahiran..."
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <date-picker
                              label="Tgl. Lahir"
                              ref="birth_date"
                              @date-changed="changeBirthDate"
                            ></date-picker>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete
                              ref="marital_status"
                              v-model="form.marital_status"
                              :rules="rules.required"
                              :items="marriageStatusOption"
                              :error-messages="
                                hasErrors &&
                                errors.hasOwnProperty('marital_status')
                                  ? errors.marital_status[0]
                                  : null
                              "
                              item-text="label"
                              item-value="value"
                              dense
                              label="Status Pernikahan"
                            >
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete
                              ref="gender"
                              v-model="form.gender"
                              :items="genderOptions"
                              :rules="rules.required"
                              :error-messages="
                                hasErrors && errors.hasOwnProperty('gender')
                                  ? errors.gender[0]
                                  : null
                              "
                              item-text="label"
                              item-value="value"
                              label="Jenis Kelamin"
                              required
                              dense
                            >
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete
                              ref="disability_id"
                              v-model="form.disability_id"
                              :items="disabilityOption"
                              item-text="label"
                              item-value="value"
                              label="Disablilitas"
                              required
                              dense
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              ref="information"
                              v-model="form.information"
                              label="Keterangan (optional)..."
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              ref="addr_street"
                              v-model="form.addr_street"
                              :rules="rules.required"
                              :error-messages="
                                hasErrors &&
                                errors.hasOwnProperty('addr_street')
                                  ? errors.addr_street[0]
                                  : null
                              "
                              label="Jalan/Dusun"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              label="RT"
                              ref="addr_rt"
                              v-model="form.addr_rt"
                              :rules="rules.required"
                              :error-messages="
                                hasErrors && errors.hasOwnProperty('addr_rt')
                                  ? errors.addr_rt[0]
                                  : null
                              "
                              @keypress="filterNumber"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              v-model="form.addr_rw"
                              ref="addr_rw"
                              :rules="rules.required"
                              :error-messages="
                                hasErrors && errors.hasOwnProperty('addr_rw')
                                  ? errors.addr_rw[0]
                                  : null
                              "
                              @keypress="filterNumber"
                              label="RW"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete
                              ref="village_id"
                              v-model="form.village_id"
                              :items="villages"
                              :rules="rules.required"
                              @input="getTpsByLocationId"
                              item-text="name"
                              item-value="id"
                              label="Lokasi"
                              required
                              dense
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete
                              ref="tps_id"
                              v-model="form.tps_id"
                              :items="tps"
                              :rules="rules.required"
                              item-text="name"
                              item-value="id"
                              label="TPS"
                              required
                              dense
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn depressed color="primary" @click="saveData">
                      Simpan
                    </v-btn>
                    <v-btn text @click="closeModal">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-card-text>
            </v-card>
          </v-layout>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from "../../components/DatePicker";
import $axios from "../../api.js";
import Alert from "../../components/Alert";
export default {
  components: {
    DatePicker,
    Alert,
  },
  mounted() {
    this.getProfile();
  },
  data() {
    return {
      menu2: false,
      isShowModal: false,
      valid: false,
      districtId: null,
      villages: [],
      tps: [],
      form: {
        tps_id: "",
        kk: "",
        nik: "",
        name: "",
        birth_place: "",
        birth_date: "",
        marital_status: "",
        gender: "",
        addr_street: "",
        addr_rt: "",
        addr_rw: "",
        disability_id: "",
        information: "",
        village_id: "",
      },
      rules: {
        nikRules: [
          (v) => !!v || "NIK tidak boleh kosong",
          (v) => v.length == 16 || "Nomor NIK Harus 16 digit",
        ],
        nameRules: [(v) => !!v.length || "Nama tidak boleh kosong"],
        required: [(v) => !!v || "isian tidak boleh kosong"],
      },
      errors: [],
      marriageStatusOption: [
        {
          label: "Belum Menikah",
          value: "b",
        },
        {
          label: "Sudah Menikah",
          value: "s",
        },
        {
          label: "Pernah Menikah",
          value: "p",
        },
      ],
      genderOptions: [
        {
          label: "Laki-laki",
          value: "l",
        },
        {
          label: "Perempuan",
          value: "p",
        },
      ],
      disabilityOption: [
        {
          label: "Tuna Daksa",
          value: 1,
        },
        {
          label: "Tuna Netra",
          value: 2,
        },
        {
          label: "Tuna Rungu/Wicara",
          value: 3,
        },
        {
          label: "Tuna Grahita",
          value: 4,
        },
        {
          label: "Disabilitas Lainnya",
          value: 5,
        },
      ],
    };
  },
  methods: {
    filterNumber: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeBirthDate(value) {
      this.form.birth_date = value;
    },
    closeModal() {
      this.isShowModal = false;
      this.clearForm();
      this.errors = [];
    },
    clearForm() {
      this.form.tps_id = "";
      this.form.kk = "";
      this.form.nik = "";
      this.form.name = "";
      this.form.birth_place = "";
      this.form.birth_date = "";
      this.form.marital_status = "";
      this.form.gender = "";
      this.form.addr_street = "";
      this.form.addr_rt = "";
      this.form.addr_rw = "";
      this.form.disability_id = "";
      this.form.information = "";
      this.form.village_id = "";
      this.$refs.form.resetValidation();
    },
    saveData() {
      this.valid = false;
      Object.keys(this.formValidation).forEach((f) => {
        if (!this.formValidation[`${f}`]) this.valid = true;
        this.$refs[`${f}`].validate(true);
      });

      if (this.$refs["form"].validate()) {
        const url = "https://simcaleg.next-it.co.id/api/dpt";
        $axios
          .post(url, this.form)
          .then(({ data }) => {
            this.closeModal();
            this.$refs.alert.show(data.message, "success");
          })
          .catch((error) => {
            let data = error.response.data;
            this.errors = data.errors;
            this.$refs.alert.show(data.message, "error");
          });
      }
    },
    getProfile() {
      $axios
        .get("/profile")
        .then(({ data }) => {
          let districtId = data.profile.locationable_id;
          this.districtId = districtId;
          this.getVillagesByDisitrictId(districtId);
        })
        .catch((error) => {
          let data = error.response.data;
          this.$refs.alert.show(data.message, "error");
        })
        .then(() => (this.$root.pageLoading = false));
    },
    getVillagesByDisitrictId(districtId) {
      const url = `https://simcaleg.next-it.co.id/api/dpt?district_id=${districtId}`;
      $axios
        .get(url)
        .then(({ data }) => {
          this.villages = data.data;
        })
        .catch((error) => {
          let data = error.response.data;
          this.$refs.alert.show(data.message, "error");
        })
        .then(() => (this.$root.pageLoading = false));
    },
    getTpsByLocationId() {
      const url = `https://simcaleg.next-it.co.id/api/dpt?village_id=${this.form.village_id}`;
      $axios
        .get(url)
        .then(({ data }) => {
          this.tps = data.data;
          this.form.tps_id = "";
        })
        .catch((error) => {
          let data = error.response.data;
          this.$refs.alert.show(data.message, "error");
        })
        .then(() => (this.$root.pageLoading = false));
    },
  },
  computed: {
    hasErrors: function () {
      return Object.keys(this.errors).length;
    },
    formValidation() {
      return {
        tps_id: this.form.tps_id,
        kk: this.form.kk,
        nik: this.form.nik,
        name: this.form.name,
        birth_place: this.form.birth_place,
        marital_status: this.form.marital_status,
        gender: this.form.gender,
        addr_street: this.form.addr_street,
        addr_rt: this.form.addr_rt,
        addr_rw: this.form.addr_rw,
        disability_id: this.form.disability_id,
        information: this.form.information,
        addr_rt: this.form.addr_rt,
        addr_rw: this.form.addr_rw,
        village_id: this.form.village_id,
      };
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Tambah Data")])]}},{key:"default",fn:function(isShowModal){return [_c('v-toolbar',{attrs:{"color":"teal","dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v("\n          Data Pendaftar\n        ")],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-card',[_c('alert',{ref:"alert"}),_c('v-card-text',[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"kk",attrs:{"counter":16,"rules":_vm.rules.nikRules,"label":"No Kartu Keluarga...","hide-details":"","required":""},on:{"keypress":_vm.filterNumber},model:{value:(_vm.form.kk),callback:function ($$v) {_vm.$set(_vm.form, "kk", $$v)},expression:"form.kk"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"nik",attrs:{"counter":16,"rules":_vm.rules.nikRules,"label":"Nomor Induk Kependudukan...","error-messages":_vm.hasErrors && _vm.errors.hasOwnProperty('nik')
                                ? _vm.errors.nik[0]
                                : null,"required":""},on:{"keypress":_vm.filterNumber},model:{value:(_vm.form.nik),callback:function ($$v) {_vm.$set(_vm.form, "nik", $$v)},expression:"form.nik"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"name",attrs:{"rules":_vm.rules.nameRules,"error-messages":_vm.hasErrors && _vm.errors.hasOwnProperty('name')
                                ? _vm.errors.name[0]
                                : null,"label":"Nama Sesuai KTP..","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"birth_place",attrs:{"rules":_vm.rules.nameRules,"error-messages":_vm.hasErrors &&
                              _vm.errors.hasOwnProperty('birth_place')
                                ? _vm.errors.birth_place[0]
                                : null,"label":"Tempat Kelahiran...","required":""},model:{value:(_vm.form.birth_place),callback:function ($$v) {_vm.$set(_vm.form, "birth_place", $$v)},expression:"form.birth_place"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('date-picker',{ref:"birth_date",attrs:{"label":"Tgl. Lahir"},on:{"date-changed":_vm.changeBirthDate}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"marital_status",attrs:{"rules":_vm.rules.required,"items":_vm.marriageStatusOption,"error-messages":_vm.hasErrors &&
                              _vm.errors.hasOwnProperty('marital_status')
                                ? _vm.errors.marital_status[0]
                                : null,"item-text":"label","item-value":"value","dense":"","label":"Status Pernikahan"},model:{value:(_vm.form.marital_status),callback:function ($$v) {_vm.$set(_vm.form, "marital_status", $$v)},expression:"form.marital_status"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"gender",attrs:{"items":_vm.genderOptions,"rules":_vm.rules.required,"error-messages":_vm.hasErrors && _vm.errors.hasOwnProperty('gender')
                                ? _vm.errors.gender[0]
                                : null,"item-text":"label","item-value":"value","label":"Jenis Kelamin","required":"","dense":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"disability_id",attrs:{"items":_vm.disabilityOption,"item-text":"label","item-value":"value","label":"Disablilitas","required":"","dense":""},model:{value:(_vm.form.disability_id),callback:function ($$v) {_vm.$set(_vm.form, "disability_id", $$v)},expression:"form.disability_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"information",attrs:{"label":"Keterangan (optional)...","required":""},model:{value:(_vm.form.information),callback:function ($$v) {_vm.$set(_vm.form, "information", $$v)},expression:"form.information"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"addr_street",attrs:{"rules":_vm.rules.required,"error-messages":_vm.hasErrors &&
                              _vm.errors.hasOwnProperty('addr_street')
                                ? _vm.errors.addr_street[0]
                                : null,"label":"Jalan/Dusun","required":""},model:{value:(_vm.form.addr_street),callback:function ($$v) {_vm.$set(_vm.form, "addr_street", $$v)},expression:"form.addr_street"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"addr_rt",attrs:{"label":"RT","rules":_vm.rules.required,"error-messages":_vm.hasErrors && _vm.errors.hasOwnProperty('addr_rt')
                                ? _vm.errors.addr_rt[0]
                                : null,"required":""},on:{"keypress":_vm.filterNumber},model:{value:(_vm.form.addr_rt),callback:function ($$v) {_vm.$set(_vm.form, "addr_rt", $$v)},expression:"form.addr_rt"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"addr_rw",attrs:{"rules":_vm.rules.required,"error-messages":_vm.hasErrors && _vm.errors.hasOwnProperty('addr_rw')
                                ? _vm.errors.addr_rw[0]
                                : null,"label":"RW","required":""},on:{"keypress":_vm.filterNumber},model:{value:(_vm.form.addr_rw),callback:function ($$v) {_vm.$set(_vm.form, "addr_rw", $$v)},expression:"form.addr_rw"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"village_id",attrs:{"items":_vm.villages,"rules":_vm.rules.required,"item-text":"name","item-value":"id","label":"Lokasi","required":"","dense":""},on:{"input":_vm.getTpsByLocationId},model:{value:(_vm.form.village_id),callback:function ($$v) {_vm.$set(_vm.form, "village_id", $$v)},expression:"form.village_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"tps_id",attrs:{"items":_vm.tps,"rules":_vm.rules.required,"item-text":"name","item-value":"id","label":"TPS","required":"","dense":""},model:{value:(_vm.form.tps_id),callback:function ($$v) {_vm.$set(_vm.form, "tps_id", $$v)},expression:"form.tps_id"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.saveData}},[_vm._v("\n                    Simpan\n                  ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeModal}},[_vm._v("Close")])],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.isShowModal),callback:function ($$v) {_vm.isShowModal=$$v},expression:"isShowModal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }